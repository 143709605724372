import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { Container, Row, Col } from 'react-bootstrap';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { Squares } from 'react-activity';

import { useHistory } from 'react-router-dom';

import axios from 'axios';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect';

var validator = require('email-validator');

const MySwal = withReactContent(Swal);

function ViewCustomer() {
  const [token, setToken] = useState(null);

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [people, setPeople] = useState(1);

  const [realEmail, setRealEmail] = useState('')

  const [currentBalance, setCurrentBalance] = useState(0);

  const [assocCode, setAssocCode] = useState('');

  const [amountToAdd, setAmountToAdd] = useState(0);

  const [amountToRemove, setAmountToRemove] = useState(0);

  const [associatedDevices, setAssociatedDevices] = useState(99999);

  function reSendSmsConfirmation() {
    if (
      window.confirm(
        "Conferma l'invio del codice d'associazione " +
          assocCode +
          ' al numero ' +
          phone +
          '?'
      )
    ) {
      var localToken = localStorage.getItem('terrerovereschemeals_token');

      axios
        .post('https://api.terreroveresche.tds.srl/sendAssocCode.php', {
          id: history.location.state.customer,
          token: localToken,
        })
        .then(function (response) {
          var response = response.data;
          console.log(response);

          if (response.status == 'ok') {
            alert('SMS Inviato correttamente');
          } else {
            alert("C'è stato un errore nell'invio.");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function topUpAmount() {
    if (amountToAdd < 1) {
      alert('Non puoi ricaricare meno di 1');
      return;
    }

    try {
      var localToken = localStorage.getItem('terrerovereschemeals_token');

      console.log(history);

      axios
        .post('https://api.terreroveresche.tds.srl/addAmount.php', {
          token: localToken,
          id: history.location.state.customer,
          amount: amountToAdd,
        })
        .then(function (response) {
          var response = response.data;

          console.log(response);
          if (response.status == 'ok') {
            alert(
              'Ricarica eseguita! Attendi 10 minuti circa per visualizzare il saldo aggiornato.'
            );
            setAmountToAdd(0);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  }

  function removeAmount() {
    if (amountToRemove < 1) {
      alert('Non puoi rimuovere meno di 1 unità');
      return;
    }

    if (currentBalance - amountToRemove < 0) {
      alert('Non puoi portare il saldo in negativo.');
      return;
    }

    try {
      var localToken = localStorage.getItem('terrerovereschemeals_token');

      console.log(history);

      axios
        .post('https://api.terreroveresche.tds.srl/removeAmount.php', {
          token: localToken,
          id: history.location.state.customer,
          amount: amountToRemove,
        })
        .then(function (response) {
          var response = response.data;

          console.log(response);
          if (response.status == 'ok') {
            alert(
              ' Operazione effettuata! Attendi fino a 10 minuti circa per visualizzare il saldo aggiornato.'
            );
            setAmountToRemove(0);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  }

  let history = useHistory();

  useEffect(() => {
    try {
      var localToken = localStorage.getItem('terrerovereschemeals_token');

      console.log(history);

      axios
        .post('https://api.terreroveresche.tds.srl/viewCustomer.php', {
          token: localToken,
          id: history.location.state.customer,
        })
        .then(function (response) {
          var response = response.data;
          if (response.status == 'ok') {
            setAssocCode(response.data.assoc_code);
            setCurrentBalance(response.data.apparent_balance);
            setName(response.data.full_name);
            setPhone(response.data.phone);
            setEmail(response.data.email.slice(0, -4));
            setRealEmail(response.data.real_email)
            setAssociatedDevices(response.data.associated_devices);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  setInterval(() => {
    try {
      var localToken = localStorage.getItem('terrerovereschemeals_token');

      console.log(history);

      axios
        .post('https://api.terreroveresche.tds.srl/viewCustomer.php', {
          token: localToken,
          id: history.location.state.customer,
        })
        .then(function (response) {
          var response = response.data;
          if (response.status == 'ok') {
            setCurrentBalance(response.data.apparent_balance);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  }, 300000);

  var params = history.location.state;

  const [pageLoaded, setPageLoaded] = useState(false);

  function finalAddCustomer() {
    console.log(name, phone, people);
    if (name.length < 1 || phone.length < 1 || people < 1) {
      MySwal.fire({
        title: <p>Compila correttamente i campi.</p>,
        icon: 'error',
        showConfirmButton: false,
        timer: 2500,
      });

      return;
    }

    try {
      var localToken = localStorage.getItem('terrerovereschemeals_token');

      axios
        .post('https://api.terreroveresche.tds.srl/editCustomer.php', {
          token: localToken,
          name: name,
          phone: phone,
          id: history.location.state.customer,
        })
        .then(function (response) {
          var response = response.data;
          console.log(response);
          if (response.status == 'ok') {
            MySwal.fire({
              title: <p>Dipendente modificato correttamente</p>,
              icon: 'success',
              showConfirmButton: false,
              timer: 2500,
              allowOutsideClick: false,
            });

            setTimeout(() => {
              history.push('/customers');
            }, 2510);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {}
  }

  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(!pageLoaded);
    }, 1000);
  }, []);

  if (!pageLoaded) {
    return (
      <div className="text-center">
        <Squares></Squares>
      </div>
    );
  }

  return (
    <div>
      <MobileView>
        <p>
          Codice associazione dipendente{' '}
          <p style={{ fontWeight: 'bold', fontSize: 20 }}>{assocCode}</p>
        </p>
      </MobileView>
      <Form>
        <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Nome e cognome
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Nome e cognome dipendente"
              onChange={(v) => setName(v.target.value)}
              value={name}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            E-mail
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="email"
              placeholder="Email"
              //   onChange={(v) => setEmail(v.target.value)}
              value={realEmail}
              readOnly
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Cellulare principale
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Cellulare"
              onChange={(v) => setPhone(v.target.value)}
              value={phone}
            />
          </Col>
        </Form.Group>

        {/* <Form.Group as={Row}>
          <Col sm={{ span: 10, offset: 2 }}>
            <Button type="button" onClick={() => reSendSmsConfirmation()}>
              Reinvia SMS Codice
            </Button>
          </Col>
        </Form.Group> */}

        <hr />

        {/* <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Saldo corrente
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="number"
              placeholder="Saldo Corrente"
              //onChange={(v) => setPeople(v.target.value)}
              step="1"
              min="1"
              readOnly
              value={parseFloat(currentBalance).toFixed(0)}
            />
          </Col>
        </Form.Group> 
                <hr />

        */}

        {/* <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Ricarica
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              placeholder="Saldo Corrente"
              onChange={(v) => setAmountToAdd(v.target.value)}
              step="1"
              min="1"
              value={amountToAdd}
            />
          </Col>
          <Col sm={2}>
            <Button
              style={{ marginLeft: 0, marginTop: 5, width: '100%' }}
              type="button"
              onClick={() => topUpAmount()}
            >
              Ricarica
            </Button>
            <Col sm={12} style={{ marginTop: 20 }}>
              N.B. La ricarica sarà visibile dopo circa 10 minuti.
            </Col>
          </Col>
        </Form.Group>

        <hr /> */}

        {/* <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Rimuovi
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="number"
              placeholder="Saldo Corrente"
              onChange={(v) => setAmountToRemove(v.target.value)}
              step="1"
              min="1"
              value={amountToRemove}
            />
          </Col>
          <Col sm={2}>
            <Button
              style={{ marginLeft: 0, marginTop: 5, width: '100%' }}
              type="button"
              onClick={() => removeAmount()}
            >
              Rimuovi
            </Button>
            <Col sm={12} style={{ marginTop: 20 }}>
              N.B. Attendi fino a 10 minuti per il saldo aggiornato.
            </Col>
          </Col>
        </Form.Group>

        <hr /> */}

        {/* <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Numero Dispositivi associati
          </Form.Label>
          <Col sm={8} style={{ marginTop: 5 }}>
            <p>{associatedDevices}</p>
          </Col>
        </Form.Group>

        <hr /> */}

        <Form.Group as={Row}>
          <Col sm={{ span: 10, offset: 2 }}>
            <Button type="button" onClick={() => finalAddCustomer()}>
              Modifica dati dipendente
            </Button>
          </Col>
        </Form.Group>
      </Form>

     {/*  <BrowserView>
        <div style={{ position: 'absolute', bottom: 0, right: 30 }}>
          <p style={{ fontSize: 100 }}>{assocCode}</p>
        </div>
      </BrowserView> */} 
    </div>
  );
}

export default ViewCustomer;
