import React, { useState, useEffect } from 'react';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Button from 'react-bootstrap/Button';
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import { useHistory } from 'react-router-dom';

import axios from 'axios';

//axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

const MySwal = withReactContent(Swal);

function Login() {
  useEffect(() => {
    try {
      var localToken = localStorage.getItem('terrerovereschemeals_token');

      axios
        .post('https://api.terreroveresche.tds.srl/loginWithToken.php', {
          params: { token: localToken },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          var response = response.data;

          console.log(response);

          if (response.status == 'ok') history.push('/placesList');
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {

      history.push('/')

    }
  }, []);

  function doLogin() {
    if (username.length < 1 || password.length < 1) {
      MySwal.fire({
        title: <p>Compila entrambi i campi</p>,
        icon: 'error',
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }

    axios
      .post('https://api.terreroveresche.tds.srl/login.php', {
        params: { username: username, password: password },
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        var response = response.data;

        console.log(response);

        if (response.status == 'error') {
          MySwal.fire({
            title: <p>Dati non riconosciuti.</p>,
            icon: 'error',
            showConfirmButton: false,
            timer: 2500,
          });
        } else if (response.status == 'ok') {
          var token = response.message;

          localStorage.setItem('terrerovereschemeals_token', token);


          history.push('/placesList');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  let history = useHistory();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  console.log(history);

  return (
    <Container>
      <Row className="text-center">
        <h1>Effettua l'accesso</h1>
      </Row>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Username</Form.Label>
        <Form.Control
          type="text"
          placeholder="Username"
          onChange={(v) => setUsername(v.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Password"
          onChange={(v) => setPassword(v.target.value)}
        />
      </Form.Group>
      <Col sm={6} className="text-center">
        <Button
          class="w-50 btn-primary btn text-center"
          onClick={() => doLogin()}
          type="submit"
        >
          Accedi
        </Button>{' '}
      </Col>
    </Container>
  );
}

export default Login;
