import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { Container, Row, Col } from 'react-bootstrap';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { Squares } from 'react-activity';

import { useHistory } from 'react-router-dom';

import axios from 'axios';

import { Checkbox } from 'pretty-checkbox-react';

import * as moment from 'moment';

var validator = require('email-validator');

const MySwal = withReactContent(Swal);

function AddDay() {
  const [token, setToken] = useState(null);

  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [description, setDescription] = useState('');

  const [yearDisabled, setYearDisabled] = useState(false);

  let history = useHistory();

  useEffect(() => {
    try {
      var localToken = localStorage.getItem('terrerovereschemeals_token');
      setToken(localToken);

      axios
        .post('https://api.terreroveresche.tds.srl/loginWithToken.php', {
          params: { token: localToken },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          var response = response.data;

          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      history.push('/');
    }
  }, []);

  var params = history.location.state;

  const [pageLoaded, setPageLoaded] = useState(false);

  function finalAddDay() {
    var $day = day.length < 2 ? '0' + day : day;
    var $month = month.length < 2 ? '0' + month : month;
    var $year = yearDisabled ? '2021' : year;

    var isValid = moment(
      $year + '-' + $month + '-' + $day,
      'YYYY-MM-DD',
      true
    ).isValid();

    if (!isValid) {
      MySwal.fire({
        title: <p>Inserisci una data valida.</p>,
        icon: 'error',
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }

    try {
      var localToken = localStorage.getItem('terrerovereschemeals_token');

      axios
        .post('https://api.terreroveresche.tds.srl/addDay.php', {
          token: localToken,
          day: $day,
          month: $month,
          year: $year,
          autoYear: yearDisabled,
          description: description,
        })
        .then(function (response) {
          var response = response.data;
          console.log(response);
          if (response.status == 'ok') {
            MySwal.fire({
              title: <p>Giorno di chiusura aggiunto correttamente</p>,
              icon: 'success',
              showConfirmButton: false,
              timer: 2500,
              allowOutsideClick: false,
            });

            setTimeout(() => {
              history.push('/calendar');
            }, 2510);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {}
  }



  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(!pageLoaded);
    }, 1000);
  }, []);

  if (!pageLoaded) {
    return (
      <div className="text-center">
        <Squares></Squares>
      </div>
    );
  }

  return (
    <div>
      <Form>
        <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Giorno di chiusura
          </Form.Label>
          <Col sm={1}>
            <Form.Control
              type="text"
              placeholder="Giorno"
              onChange={(v) => setDay(v.target.value)}
              maxLength={2}
              value={day}
            />
          </Col>
          <Col sm={1}>
            <Form.Control
              type="text"
              placeholder="Mese"
              onChange={(v) => setMonth(v.target.value)}
              maxLength={2}
              value={month}
            />
          </Col>
          <Col sm={1}>
            <Form.Control
              type="text"
              placeholder="Anno"
              onChange={(v) => setYear(v.target.value)}
              disabled={yearDisabled}
              maxLength={4}
              value={year}
            />
          </Col>
        </Form.Group>

        <hr />
        <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Descrizione
          </Form.Label>
          <Col sm={3}>
            <Form.Control
              as="textarea"
              placeholder="e.g. Patrono"
              rows={3}
              value={description}
              onChange={(v) => setDescription(v.target.value)}
            />
          </Col>
        </Form.Group>

        <hr />

        <Form.Group as={Row} controlId="formHorizontalEmail">
          <Form.Label column sm={2}>
            Ricorrente
          </Form.Label>
          <Col sm={10} style={{ marginTop: 10 }}>
            <Checkbox
              checked={yearDisabled}
              onChange={() => {
                setYear('');
                setYearDisabled(!yearDisabled);
              }}
            ></Checkbox>
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Col sm={{ span: 10, offset: 2 }}>
            <Button type="button" onClick={() => finalAddDay()}>
              Aggiungi giorno di chiusura
            </Button>
          </Col>
        </Form.Group>
      </Form>
    </div>
  );
}

export default AddDay;
