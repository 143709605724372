import React, { useState, useEffect, useMemo, useReducer } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { useTable } from 'react-table';
import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import Button from 'react-bootstrap/Button';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Form from 'react-bootstrap/Form';
import $ from 'jquery';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Calendar({ route }) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  let history = useHistory();

  //let place = history.location.state.place

  //console.log(history);

  //const [places, setPlaces] = useState([]);
  const MySwal = withReactContent(Swal);

  const [days, setDays] = useState([]);

  const [daysOpen, setDaysOpen] = useState([]);

  function addDay() {
    history.push('/addDay/:new');
  }

  useEffect(() => {
   



  }, [daysOpen]);

  function saveDaysOpenToDb() {


    //console.log(daysOpen)

    try{

      var localToken = localStorage.getItem('terrerovereschemeals_token');


      axios
      .post('https://api.terreroveresche.tds.srl/saveDaysOpen.php', {
        params: { token: localToken, daysOpen: daysOpen },
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        var response = response.data;
  
       console.log(response);
      
  
        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  
    }
    catch(e) {

    }



  }

  useEffect(() => {
    retrieveDays();
  }, []);

  function retrieveDays() {
    try {
      var localToken = localStorage.getItem('terrerovereschemeals_token');

      axios
        .post('https://api.terreroveresche.tds.srl/loginWithToken.php', {
          params: { token: localToken },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          var response = response.data;

         // console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      history.push('/');
    }

    var token = localStorage.getItem('terrerovereschemeals_token');

    axios
      .post(
        'https://api.terreroveresche.tds.srl/retrieveCalendarDaysList.php',
        {
          params: { token: token },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(function (response) {
        var response = response.data;

        if (response.status == 'ok') {
          var days = JSON.parse(response.message);

          setTimeout(() => {
            setDays(days);
          }, 1500);
        }

        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .post('https://api.terreroveresche.tds.srl/retrieveDaysOpen.php', {
        params: { token: token },
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        var response = response.data;

       // console.log(response, 'daysOpen');
        if (response.status == 'ok') {
          setTimeout(() => {
            setDaysOpen(response.message);
          }, 1500);

        //  console.log(response.message);
        }

        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function deleteDay(id) {
    try {
      var localToken = localStorage.getItem('terrerovereschemeals_token');

      axios
        .post('https://api.terreroveresche.tds.srl/deleteDay.php', {
          params: { token: localToken, dayId: id },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          var response = response.data;

         // console.log(response);
          if (response.status == 'ok') {
            retrieveDays();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      history.push('/');
    }
  }

  function renderDeleteDayButton(id) {
    return (
      <Button
        onClick={() => {
          deleteDay(id);
        }}
        size="sm"
        variant="dark"
      >
        Elimina
      </Button>
    );
  }

  const columns = [
    /*{
      dataField: 'id',
      text: 'Rif',

    },*/
    {
      dataField: 'day',
      text: 'Giorno',
    },
    {
      dataField: 'recurring',
      text: 'Ricorrente',
    },
    {
      dataField: 'description',
      text: 'Descrizione',
      formatter: (rowContent, row) => {
        return <div>{rowContent != null ? rowContent : ''}</div>;
      },
    },
    {
      dataField: 'actions',
      text: 'Azioni',
      formatter: (rowContent, row) => {
        return (
          <div>
            {row.fromCron != 1
              ? renderDeleteDayButton(row.id)
              : 'Inserito automaticamente.'}
          </div>
        );
      },
    },
  ];

  /* const products = [{ id: 1, name: 'ciao', price: 2.34 },{ id: 1, name: 'ciao', price: 2.34 },{ id: 1, name: 'ciao', price: 2.34 },{ id: 1, name: 'ciao', price: 2.34 }]; */

  return (
    <div>
      {' '}
      <div className="text-center">
        <Button
          onClick={() => {
            addDay();
          }}
          variant="dark"
          style={{ marginBottom: 20 }}
        >
          Aggiungi giorno di chiusura
        </Button>
      </div>
      {typeof hiddenFeaturesVisible !== 'undefined' &&  <Container style={{ marginTop: 30, marginBottom: 30 }}>
        <Row>
          {daysOpen.map(function (element, index) {
            return (
              <Col>
                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    checked={element.checked}
                    data-dayindex={index}
                    onChange={(v) => {
                      var x = daysOpen;
                      var dayIndex = v.target.getAttribute('data-dayindex');
                      x[dayIndex].checked = !x[dayIndex].checked;
                      //console.log(dayIndex);
                      setDaysOpen(x);
                      forceUpdate();
                      saveDaysOpenToDb()
                    }}
                    label={element.italian_name}
                  />
                  {daysOpen[index].checked && (
                    <div style={{ marginTop: 10 }}>
                      <div>
                        Dalle
                        <Form.Control
                          data-dayindex={index}
                          type="time"
                          placeholder="10:00"
                          value={
                            element.from_hours + ':' + element.from_minutes
                          }
                          onChange={(v) => {
                            var x = daysOpen;
                            var dayIndex = v.target.getAttribute(
                              'data-dayindex'
                            );
                            var thisHours = v.target.value.split(':')[0];
                            var thisMinutes = v.target.value.split(':')[1];
                            x[dayIndex].from_hours = thisHours;
                            x[dayIndex].from_minutes = thisMinutes;

                            var startTime = thisHours + thisMinutes;
                            var endTime =
                              x[dayIndex].to_hours + x[dayIndex].to_minutes;

                            if (+startTime >= +endTime) {

                              alert('Inserisci un intervallo di tempo valido');
                              x[dayIndex].from_hours = '00';
                              x[dayIndex].from_minutes = '00';
                              x[dayIndex].to_hours = '23';
                              x[dayIndex].to_minutes = '59';

                            }

                            setDaysOpen(x);
                            forceUpdate();
                            saveDaysOpenToDb()


                          }}
                        />
                      </div>
                      <div style={{ marginTop: 5 }}>
                        Alle
                        <Form.Control
                          data-dayindex={index}
                          type="time"
                          placeholder="18:00"
                          value={element.to_hours + ':' + element.to_minutes}
                          onChange={(v) => {
                            var x = daysOpen;
                            var dayIndex = v.target.getAttribute(
                              'data-dayindex'
                            );
                            var thisHours = v.target.value.split(':')[0];
                            var thisMinutes = v.target.value.split(':')[1];

                            x[dayIndex].to_hours = thisHours;
                            x[dayIndex].to_minutes = thisMinutes;

                            var startTime =
                              x[dayIndex].from_hours + x[dayIndex].from_minutes;
                            var endTime = thisHours + thisMinutes;

                            if (+startTime >= +endTime) {

                              alert('Inserisci un intervallo di tempo valido');
                              x[dayIndex].from_hours = '00';
                              x[dayIndex].from_minutes = '00';
                              x[dayIndex].to_hours = '23';
                              x[dayIndex].to_minutes = '59';

                            }

                            setDaysOpen(x);
                            forceUpdate();
                            saveDaysOpenToDb()


                          }}
                        />
                      </div>
                    </div>
                  )}
                </Form.Group>
              </Col>
            );
          })}
        </Row>
      </Container>}
      {<BootstrapTable keyField="id" data={days} columns={columns} />}
    </div>
  );

  //return <div></div>;
}

export default Calendar;
