import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useEffect, useState } from 'react';
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';

import { Formik } from 'formik';

import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from 'react-router-dom';

import Login from './components/login';
import PlacesList from './components/placesList';
import Add from './components/add';
import ViewPlace from './components/viewPlace';
import Customers from './components/customers';
import AddCustomer from './components/addCustomer';
import ViewCustomer from './components/viewCustomer';
import EditPlace from './components/editPlace.js'
import Transactions from './components/transactions.js'
import Report from './components/Report.js'
import Calendar from './components/calendar.js'
import AddDay from './components/addDay';



function App() {
  //const [token, setToken] = useState();

  const token = localStorage.getItem('terrerovereschemeals_token');

  console.log(token);

  useEffect(() => {
    document.body.style.backgroundColor = '##d9d9d9';
  }, []);

  const history = useHistory();

  function logout() {
    localStorage.removeItem('terrerovereschemeals_token');
  }

  return (
    <div className="App">
      <Navbar bg="light" expand="lg">
        <Navbar.Brand>Terre Roveresche</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/placesList">Locali</Nav.Link>

            <Nav.Link href="/customers">Dipendenti</Nav.Link>

            <Nav.Link href="/transactions">Transazioni</Nav.Link>

            <Nav.Link href="/calendar">Giorni di chiusura</Nav.Link>

            <hr></hr>
            <Nav.Link href="/" onClick={logout}>
              Esci
            </Nav.Link>
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          {/* <Form inline>
            <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            <Button variant="outline-success">Search</Button>
          </Form> */}
        </Navbar.Collapse>
      </Navbar>
      <div className="wrapperAmount">
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <Route path="/add">
              <Add />
            </Route>
            <Route path="/placesList">
              <PlacesList />
            </Route>
            <Route path="/viewPlace">
              <ViewPlace />
            </Route>
            <Route path="/customers">
              <Customers />
            </Route>
            <Route path="/addCustomer">
              <AddCustomer />
            </Route>
            <Route path="/addDay">
              <AddDay />
            </Route>
            <Route path="/viewCustomer">
              <ViewCustomer />
            </Route>
            <Route path="/report">
              <Report />
            </Route>
            <Route path="/editPlace">
              <EditPlace />
            </Route>
            <Route path="/transactions">
              <Transactions />
            </Route>
            <Route path="/calendar">
              <Calendar />
            </Route>
          </Switch>
        </BrowserRouter>
      </div>

      <br />
    </div>
  );
}

export default App;
