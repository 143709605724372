import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useTable } from 'react-table';
import BootstrapTable from 'react-bootstrap-table-next';

import { Squares } from 'react-activity';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';


import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const MySwal = withReactContent(Swal);

function Transactions() {
  let history = useHistory();

  const location = useLocation();

  const [trans, setTrans] = useState([]);

  function redirectAddCustomer() {
    history.push('/addCustomer/:new');
  }

  function viewCustomer(id) {
    history.push({
      pathname: '/viewCustomer',
      state: { customer: id },
    });
  }

  function delCustomer(id) {
    if (window.confirm('Eliminare il dipendente?')) {
      var localToken = localStorage.getItem('terrerovereschemeals_token');

      axios
        .post('https://api.terreroveresche.tds.srl/deleteCustomer.php', {
          token: localToken,
          place: id,
        })
        .then(function (response) {
          var response = response.data;
          if (response.status == 'ok') {
            MySwal.fire({
              title: <p>Dipendente eliminato</p>,
              icon: 'success',
              showConfirmButton: false,
              timer: 2500,
              allowOutsideClick: false,
            });
            setTimeout(() => {
              window.location.reload();
            }, 2600);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    try {
      var localToken = localStorage.getItem('terrerovereschemeals_token');

      axios
        .post('https://api.terreroveresche.tds.srl/loginWithToken.php', {
          params: { token: localToken },
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          var response = response.data;

          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      history.push('/');
    }

    var token = localStorage.getItem('terrerovereschemeals_token');

    axios
      .post('https://api.terreroveresche.tds.srl/transactionsList.php', {
        params: { token: token },
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        var response = response.data;

        if (response.status == 'ok') {
          var trans = JSON.parse(response.message);

          //console.log(trans)

          var transReviewed = []

          trans.forEach(function(resp) {
            if(resp.type.toLowerCase() == "pagamento" || resp.type.toLowerCase() == "Payment")  {

              transReviewed.push(resp)

            }
          })

          setTimeout(() => {
            setTrans(transReviewed);
          }, 1500);
        }

        //console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const { SearchBar } = Search;

  const { ExportCSVButton } = CSVExport;

  const columns = [
      {
      dataField: 'transID',
      text: 'Rif',
    },
    {
      dataField: 'actual_user',
      text: 'Nome utilizzatore',
      formatter: (rowContent, row) => {
        if (rowContent != 'N/A') {
          return <div style={{ fontWeight: 'bold' }}>{rowContent}</div>;
        } else {
          return <div style={{ fontWeight: 'normal' }}>{rowContent}</div>;
        }
      },
    },
    {
      dataField: 'customer',
      text: 'Dipendente',
      formatter: (rowContent, row) => {
        if (rowContent != 'N/A') {
          return <div style={{ fontWeight: 'bold' }}>{rowContent}</div>;
        } else {
          return <div style={{ fontWeight: 'normal' }}>{rowContent}</div>;
        }
      },
    },
    {
      dataField: 'amount',
      text: 'Importo',
      formatter: (rowContent, row) => {
        if (rowContent != 'N/A') {
          return <div style={{ fontWeight: 'bold' }}>{rowContent}</div>;
        } else {
          return <div style={{ fontWeight: 'normal' }}>{rowContent}</div>;
        }
      },
    },
    {
      dataField: 'type',
      text: 'Tipo',
      formatter: (rowContent, row) => {
        if (rowContent != 'N/A') {
          return <div style={{ fontWeight: 'bold' }}>{rowContent}</div>;
        } else {
          return <div style={{ fontWeight: 'normal' }}>{rowContent}</div>;
        }
      },
    },
    {
      dataField: 'place',
      text: 'Locale',
      formatter: (rowContent, row) => {
        if (rowContent != 'N/A') {
          return <div style={{ fontWeight: 'bold' }}>{rowContent}</div>;
        } else {
          return <div style={{ fontWeight: 'normal' }}>{rowContent}</div>;
        }
      },
    },
    {
      dataField: 'datetime',
      text: 'Data e ora',
      formatter: (rowContent, row) => {
        if (rowContent != 'N/A') {
          return <div style={{ fontWeight: 'bold' }}>{rowContent}</div>;
        } else {
          return <div style={{ fontWeight: 'normal' }}>{rowContent}</div>;
        }
      },
    },
  ];

  /* const products = [{ id: 1, name: 'ciao', price: 2.34 },{ id: 1, name: 'ciao', price: 2.34 },{ id: 1, name: 'ciao', price: 2.34 },{ id: 1, name: 'ciao', price: 2.34 }]; */

  return (
    <div className="text-center">

<ExcelFile filename="transazioni" element={<button style={{borderColor:'white', backgroundColor:'white', borderWidth:0, fontWeight:'normal', fontSize:20}} >Esporta Excel</button>}>
                <ExcelSheet data={trans} name="Transazioni">
                    <ExcelColumn label="Rif" value="transID"/>
                    <ExcelColumn label="Nome utilizzatore" value="actual_user"/>
                    <ExcelColumn label="Dipendente" value="customer"/>
                    <ExcelColumn label="Importo" value="amount"/>
                    <ExcelColumn label="Tipo" value="type"/>
                    <ExcelColumn label="Locale" value="place"/>        
                    <ExcelColumn label="Data e ora" value="datetime"/>        

                </ExcelSheet>

            </ExcelFile>

      {trans.length > 0 && (
        <ToolkitProvider
          keyField="transID"
          data={trans}
          columns={columns}
          exportCSV={ {
            fileName: 'transazioni.csv',
            ignoreHeader:false,
          } }
          search
        >
          {(props) => (
            <div>
              {/* <ExportCSVButton {...props.csvProps}>
               Esporta CSV
              </ExportCSVButton> */}
              <hr />
              <SearchBar placeholder='Cerca...' { ...props.searchProps } /> 
              <hr />

              <BootstrapTable
{ ...props.baseProps }

              />
            </div>
          )}
        </ToolkitProvider>
      )}
      {trans.length < 1 && <Squares></Squares>}
    </div>
  );

  //return <div></div>;
}

export default Transactions;
