import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { Container, Row, Col } from 'react-bootstrap';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import moment from 'moment/min/moment-with-locales';

import Alert from 'react-bootstrap/Alert';

import Uploady from '@rpldy/uploady';
import UploadButton from '@rpldy/upload-button';
import UploadPreview from '@rpldy/upload-preview';

import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { Squares } from 'react-activity';

import { useHistory } from 'react-router-dom';

import ImageUpload from 'image-upload-react';
//important for getting nice style.
import 'image-upload-react/dist/index.css';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import AutoComplete from 'react-google-autocomplete';

import axios from 'axios';

var validator = require('email-validator');

const MySwal = withReactContent(Swal);

function Report() {
  let history = useHistory();

  const [token, setToken] = useState(null);

  const [pageLoaded, setPageLoaded] = useState(false);

  const [monthsList, setMonthsList] = useState([]);

  const [reportDataLoaded, setReportDataLoaded] = useState(false);

  const [currentTotal, setCurrentTotal] = useState(0);

  const [currentMonth, setCurrentMonth] = useState('');

  const [reportList, setReportList] = useState([]);

  const { SearchBar } = Search;

  const { ExportCSVButton } = CSVExport;

  const columns = [
    {
      dataField: 'id',
      text: 'Rif',
    },
    {
      dataField: 'full_name',
      text: 'Nome utilizzatore',
      formatter: (rowContent, row) => {
        if (rowContent != 'N/A') {
          return <div style={{ fontWeight: 'bold' }}>{rowContent}</div>;
        } else {
          return <div style={{ fontWeight: 'normal' }}>{rowContent}</div>;
        }
      },
    },
    {
      dataField: 'customer_name',
      text: 'Dipendente',
      formatter: (rowContent, row) => {
        if (rowContent != 'N/A') {
          return <div style={{ fontWeight: 'bold' }}>{rowContent}</div>;
        } else {
          return <div style={{ fontWeight: 'normal' }}>{rowContent}</div>;
        }
      },
    },
    {
      dataField: 'amount',
      text: 'Importo',
      formatter: (rowContent, row) => {
        if (rowContent != 'N/A') {
          return (
            <div style={{ fontWeight: 'bold' }}>
              -{parseFloat(rowContent).toFixed(0)}
            </div>
          );
        } else {
          return <div style={{ fontWeight: 'normal' }}>{rowContent}</div>;
        }
      },
    },
    /*{
      dataField: 'type',
      text: 'Tipo',
      formatter: (rowContent, row) => {
        if (rowContent != 'N/A') {
          return <div style={{ fontWeight: 'bold' }}>{rowContent}</div>;
        } else {
          return <div style={{ fontWeight: 'normal' }}>{rowContent}</div>;
        }
      },
    },*/
    {
      dataField: 'place',
      text: 'Locale',
      formatter: (rowContent, row) => {
        if (rowContent != 'N/A') {
          return <div style={{ fontWeight: 'bold' }}>{rowContent}</div>;
        } else {
          return <div style={{ fontWeight: 'normal' }}>{rowContent}</div>;
        }
      },
    },
    {
      dataField: 'datetime',
      text: 'Data e ora',
      formatter: (rowContent, row) => {
        if (rowContent != 'N/A') {
          return <div style={{ fontWeight: 'bold' }}>{rowContent}</div>;
        } else {
          return <div style={{ fontWeight: 'normal' }}>{rowContent}</div>;
        }
      },
    },
  ];

  var params = history.location.state;

  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(!pageLoaded);
    }, 1000);
  }, []);

  useEffect(() => {
    moment.locale('it');

    var $months = [];

    var monthsRequired = 12;

    for (let i = monthsRequired; i >= 1; i--) {
      $months.push({
        value: moment().subtract(i, 'months').format('MM-YYYY'),
        text: moment().subtract(i, 'months').format('MMMM YYYY'),
      });
    }

    $months.push({
      value: moment().format('MM-YYYY'),
      text: moment().format('MMMM YYYY'),
    });

    var monthsRequired = 0;

    for (let i = 1; i <= monthsRequired; i++) {
      $months.push({
        value: moment().add(i, 'months').format('MM-YYYY'),
        text: moment().add(i, 'months').format('MMMM YYYY'),
      });
    }

    var months = [];

    /* $months.forEach(function (resp) {
      var x = resp.text.split(' ')[0];
      if (x == 'January') x = 'Gennaio';
      else if (x == 'February') x = 'Febbraio';
      else if (x == 'March') x = 'Marzo';
      else if (x == 'April') x = 'Aprile';
      else if (x == 'May') x = 'Maggio';
      else if (x == 'June') x = 'Giugno';
      else if (x == 'July') x = 'Luglio';
      else if (x == 'August') x = 'Agosto';
      else if (x == 'September') x = 'Settembre';
      else if (x == 'October') x = 'Ottobre';
      else if (x == 'November') x = 'Novembre';
      else if (x == 'December') x = 'Dicembre';
      else x = 'ERROR';

      months.push(x + ' ' + resp.text.split(' ')[1]);
    }); */

    console.log($months);

    setMonthsList($months);
  }, []);

  if (!pageLoaded) {
    return (
      <div className="text-center">
        <Squares></Squares>
      </div>
    );
  }

  function retrieveTransactionsForSpecificMonth(month, monthText) {
    axios
      .post(
        'https://api.terreroveresche.tds.srl/retrieveTransactionsForSpecificMonth_from_admin.php',
        {
          passpartout: 'VwmbSUXpizPuuyokMWwB',
          place: params.place,
          month: month,
        }
      )
      .then(function (response) {
        var response = response.data;
        if (response.status == 'ok') {
          setReportDataLoaded(true);
          setCurrentMonth(monthText);
          setReportList(response.transactions);
          setCurrentTotal(response.total);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div>
      <div style={{flexDirection:'row'}}>
      {monthsList.map((element) => {
        return (
            <Button
              onClick={() => {
                retrieveTransactionsForSpecificMonth(
                  element.value.toString(),
                  element.text.toString()
                );
              }}
              style={{
                backgroundColor: 'black',
                paddingHorizontal: 20,
                paddingVertical: 10,
                borderRadius: 50,
                marginLeft: 5,
                marginRight: 5,
                marginBottom: 5,
                justifyContent: 'center',
              }}
            >
              <p style={{ textTransform: 'capitalize', color: 'white' }}>
                {element.text}
              </p>
            </Button>
        );
      })}
      </div>
      {reportDataLoaded && (
        <div style={{ marginTop: 20 }}>
          <hr />

          <div>
            {/*reportList.map((element2) => {
              return (
                <div>
                  <Alert key={element2.id} variant={'dark'}>
                    (Rif: {element2.id}) - {element2.full_name} - {parseFloat(element2.amount).toFixed(0)} Saldo -{' '}
                    {element2.datetime.split(" ")[0].split("-").reverse().join("/")}{' '}{element2.datetime.split(" ")[1].split(":").slice(0, -1).join(":")}
                  </Alert>
                </div>
              );
            })*/}
            <div className="text-center">
              {reportList.length > 0 && (
                <ToolkitProvider
                  keyField="transID"
                  data={reportList}
                  columns={columns}
                  exportCSV={{
                    fileName: 'report.csv',
                    ignoreHeader: false,
                  }}
                  search
                >
                  {(props) => (
                    <div>
                      <ExportCSVButton {...props.csvProps}>
                        Esporta CSV
                      </ExportCSVButton>
                      <div style={{ marginTop: 20 }}>
                        <SearchBar
                          placeholder="Cerca..."
                          {...props.searchProps}
                        />{' '}
                      </div>
                      <hr />

                      <h2 className="text-center">
                        Report per il mese{' '}
                        <big
                          style={{
                            textTransform: 'capitalize',
                            fontWeight: 'bold',
                          }}
                        >
                          {currentMonth}
                        </big>
                      </h2>
                      <h2
                        className="text-center"
                        style={{ marginBottom: 25, marginTop: 30 }}
                      >
                        Totale{' '}
                        <big style={{ fontWeight: 'bold' }}>{currentTotal}</big>
                      </h2>
                      <BootstrapTable {...props.baseProps} />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Report;
